import axios from 'axios'
import { showToast } from '@/utils/toastify'
import { getCookie, setCookie } from 'cookies-next'
import * as errorCodes from '@/constants/error-codes'

const $axios = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_BASE}/api`,
})

const onResponse = (response) => {
  setSessionCookie(response.headers)
  return response
}

function setSessionCookie(headers) {
  if (headers['access-token'] && headers['access-token'] != '') {
    setCookie('access-token', headers['access-token'])
    setCookie('token-type', headers['token-type'])
    setCookie('uid', headers['uid'])
    setCookie('expiry', headers['expiry'])
    setCookie('client', headers['client'])
  }
}

const onError = (error) => {
  if (['auth/validate_token'].includes(error.config?.url)) {
    return Promise.reject(error)
  }

  if (
    [errorCodes.AWS_SENDING_DOMAIN_ERR, errorCodes.AWS_SANDBOX_ERR].includes(
      error.response?.data?.code
    )
  ) {
    return Promise.reject(error)
  }

  if (error.response.data?.message) {
    showToast('danger', 'Error', error.response.data.message, error.response.data.suggestion, error.response.data.url)
  }

  if (error.response.data?.errors?.full_messages) {
    error.response.data.errors.full_messages.forEach((message) => {
      showToast('danger', 'Error', message)
    })
  }

  if (error.response.data?.errors) {
    error.response.data.errors.forEach((message) => {
      showToast('danger', 'Error', message)
    })
  }
  return Promise.reject(error)
}

const headers = () => {
  let h = {}
  h['access-token'] = getCookie('access-token')
  h['token-type'] = getCookie('token-type')
  h['uid'] = getCookie('uid')
  h['expiry'] = getCookie('expiry')
  h['client'] = getCookie('client')
  h['content-type'] = 'application/json'
  return h
}

// $axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   config.headers = {...config.headers, ...headers}
//   debugger
//   return config;

// }, function (error) {
//   // Do something with request error
//   return onError(error)
// });

$axios.interceptors.response.use(
  (response) => onResponse(response),
  (error) => onError(error)
)

export default $axios
