import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchCompanyAddress = createAsyncThunk(
  'address/fetchCompanyAddress',
  async () => {
    const response = await api.addresses.companyAddress()
    return response.data
  }
)

const initialState = {
  companyAddress: null,
  loading: true
}

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCompanyAddress: (state, action) => {
      state.companyAddress = action.payload
    }
  },
  extraReducers: {
    [fetchCompanyAddress.pending]: (state) => {
      state.loading = true
    },
    [fetchCompanyAddress.fulfilled]: (state, action) => {
      state.companyAddress = action.payload
      state.loading = false
    },
    [fetchCompanyAddress.rejected]: (state, action) => {
      state.companyAddress = null
      state.loading = false
    }
  },
})

export const { setCompanyAddress } = addressSlice.actions

export default addressSlice.reducer
