import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';

export const fetchAutomations = createAsyncThunk(
  'automations/fetchAutomations',
  async(params) => {
    const response = await api.automations.index(params);
    return response.data;
  }
);

export const fetchAutomation = createAsyncThunk(
  'automations/fetchAutomation',
  async(id) => {
    const response = await api.automations.show(id);
    return response.data;
  }
);

const initialState = {
  automationData: {
    data: [],
    page: 1,
    perPage: 10,
    total: 0
  },
  currentAutomation: { name: 'Untitled' },
  fetchingCurrentAutomation: true,
  loading: true,
  error: null,
};

export const automationsSlice = createSlice({
  name: 'automations',
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [fetchAutomations.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAutomations.fulfilled]: (state, action) => {
      state.automationData = action.payload;
      state.loading = false;
    },
    [fetchAutomations.rejected]: (state, action) => {
      state.automationData = {data: [], page: 1, perPage: 10};
      state.loading = false;
    },
    [fetchAutomation.pending]: (state, action) => {
      state.fetchingCurrentAutomation = true;
    },
    [fetchAutomation.fulfilled]: (state, action) => {
      state.currentAutomation = action.payload;
      state.fetchingCurrentAutomation = false;
    },
    [fetchAutomation.rejected]: (state, action) => {
      state.currentAutomation = {name: 'Untitled'};
      state.fetchingCurrentAutomation = false;
    }
  }  
});



export default automationsSlice.reducer;