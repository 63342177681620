import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchTemplates = createAsyncThunk(
  'whatsapp/fetchTemplates',
  async (params) => {
    const response = await api.whatsApp.templates(params)
    return response.data
  }
)

// export const fetchAllCampaigns = createAsyncThunk(
//   'campaigns/fetchAll',
//   async(params) => {
//     const response = await api.campaigns.all(params)
//     return response.data
//   }
// )

export const fetchTemplate = createAsyncThunk(
  'whatsapp/fetchTemplate',
  async (id) => {
    const response = await api.whatsApp.show(id)
    return response.data
  }
)

const initialState = {
  templatesData: {
    data: [],
    page: 1,
    perPage: 10,
  },
  loadingTemplates: false,
  currentTemplate: {
    name: 'Untitled',
    footerText: '',
    headerType: 'none',
    headerText: '',
    bodyText: '',
    headerExamples: [],
    bodyExamples: [],
    language: 'en_US',
    category: 'marketing',
    type: 'whatsapp',
    templateButtons: [],
  },
  variables: {
    header: [],
    body: [],
  },
  fetchingCurrentTemplate: true,
}

export const whatsappSlice = createSlice({
  name: 'whatsapp',
  initialState,
  reducers: {
    resetTemplate: (state, action) => {
      state.currentTemplate = initialState.currentTemplate
      state.variables = initialState.variables
    },
    setTemplate: (state, action) => {
      state.currentTemplate = action.payload
    },
    updateHeaderVariables: (state, action) => {
      state.variables.header = action.payload
      console.log(state.variables.header)
    },
    updateBodyVariables: (state, action) => {
      state.variables.body = action.payload
    },
  },
  extraReducers: {
    [fetchTemplates.pending]: (state, action) => {
      state.loadingTemplates = true
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      state.templatesData = action.payload
      state.loadingTemplates = false
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.templatesData = { data: [], page: 1, perPage: 10 }
      state.loadingTemplates = false
    },
    [fetchTemplate.pending]: (state, action) => {
      state.fetchingCurrentTemplate = true
    },
    [fetchTemplate.fulfilled]: (state, action) => {
      state.currentTemplate = action.payload
      state.fetchingCurrentTemplate = false
    },
    [fetchTemplate.rejected]: (state, action) => {
      state.currentTemplate = {}
      state.fetchingCurrentTemplate = false
    },
  },
})

export const {
  setTemplate,
  updateBodyVariables,
  updateHeaderVariables,
  resetTemplate,
} = whatsappSlice.actions
export default whatsappSlice.reducer
