import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchPlans = createAsyncThunk(
  'plans/fetchPlans',
  async(params) => {
    const response = await api.plans.index(params)
    return response.data
  }
)

const initialState = {
  list: [],
  currentPlan: null,
  loading: true,
  error: null
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlan: (state, action) => {
      state.currentPlan = action.payload
    }
  },
  extraReducers: {
    [fetchPlans.pending]: state => {
      state.loading = true
    },
    [fetchPlans.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchPlans.rejected]: (state, action) => {
      state.lists = []
      state.loading = false
    }
  }
})


export const {setPlan} = plansSlice.actions

export default plansSlice.reducer
