import { configureStore } from '@reduxjs/toolkit'
import listReducer from './listsSlice'
import contactsReducer from './contactsSlice'
import campaignsReducer from './campaignsSlice'
import templatesReducer from './templatesSlice'
import plansReducer from './plansSlice'
import sessionReducer from './sessionSlice'
import dashboardReducer from './dashboardSlice'
import sentEmailReducer from './sentEmailSlice'
import addressReducer from './addressSlice'
import automationFormReducer from './automationFormSlice'
import automationsSlice from './automationsSlice'
import whatsappSlice from './whatsappSlice'
import whatsappCampaignSlice from './whatsappCampaignSlice'
import waInboxSlice from './waInboxSlice'
export const store = configureStore({
  reducer: {
    lists: listReducer,
    contacts: contactsReducer,
    campaigns: campaignsReducer,
    templates: templatesReducer,
    plans: plansReducer,
    sessions: sessionReducer,
    dashboard: dashboardReducer,
    sentEmail: sentEmailReducer,
    address: addressReducer,
    automationForm: automationFormReducer,
    automations: automationsSlice,
    whatsapp: whatsappSlice,
    whatsappCampaigns: whatsappCampaignSlice,
    waInbox: waInboxSlice,
  },
})

