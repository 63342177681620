import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async(params) => {
    const response = await api.campaigns.index(params)
    return response.data
  }
)

export const fetchAllCampaigns = createAsyncThunk(
  'campaigns/fetchAll',
  async(params) => {
    const response = await api.campaigns.all(params)
    return response.data
  }
)

export const fetchCampaign = createAsyncThunk(
  'campaigns/fetchCampaign',
  async(id) => {
    const response = await api.campaigns.show(id)
    return response.data
  }
)

export const fetchMailEvents = createAsyncThunk(
  'campaigns/fetchMailEvents',
  async({ id, params }) => {
    const response = await api.campaigns.mailEvents(id, params)
    return response.data
  }
)

export const duplicateCampaign = createAsyncThunk(
  'campaigns/duplicateCampaign',
  async(id) => {
    const response = await api.campaigns.duplicate(id)
    return response.data
  }
)

export const createCampaignTemplate = createAsyncThunk(
  'campaigns/createCampaignTemplate',
  async(id) => {
    const response = await api.campaigns.saveAsTemplate(id)
    return response.data
  }
)

const initialState = {
  campaignMailEvents: {
    data: [],
    page: 1,
    perPage: 20,
    totalPages: 0,
    total: 0
  },
  campaignsData: {
    data: [],
    page: 1,
    perPage: 10
  },
  allCampaigns: [],
  loadingAllCampaigns: false,
  currentCampaign: { name: 'Untitled' },
  campaignFormValid: false,
  fetchingCurrentCampaign: true,
  loading: true,
  error: null
}

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaign: (state, action) => {
      state.currentCampaign = action.payload
    },
    setCampaignFormValid: (state, action) => {
      state.campaignFormValid = action.payload
    }
  },
  extraReducers: {
    [fetchCampaigns.pending]: state => {
      state.loading = true
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.campaignsData = action.payload
      state.loading = false
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.campaignsData = {data: [], page: 1, perPage: 10}
      state.loading = false
    },
    [fetchCampaign.pending]: state => {
      state.fetchingCurrentCampaign = true
    },
    [fetchCampaign.fulfilled]: (state, action) => {
      state.currentCampaign = action.payload
      state.fetchingCurrentCampaign = false
    },
    [fetchCampaign.rejected]: (state, action) => {
      state.campaigns = {}
      state.fetchingCurrentCampaign = false
    },
    [fetchMailEvents.pending]: state => {
      state.loading = true
    },
    [fetchMailEvents.fulfilled]: (state, action) => {
      state.campaignMailEvents = action.payload
      state.loading = false
    },
    [fetchMailEvents.rejected]: (state, action) => {
      state.campaignMailEvents = {
        data: [],
        page: 1,
        perPage: 20,
        totalPages: 0,
        total: 0
      }
      state.loading = false
    },
    [duplicateCampaign.pending]: state => {
      state.loading = true
    },
    [duplicateCampaign.fulfilled]: (state, action) => {
      state.loading = false
    },
    [duplicateCampaign.rejected]: (state, action) => {
      state.loading = false
    },
    [createCampaignTemplate.pending]: state => {
      state.loading = true
    },
    [createCampaignTemplate.fulfilled]: (state, action) => {
      state.loading = false
    },
    [createCampaignTemplate.rejected]: (state, action) => {
      state.loading = false
    },
    [fetchAllCampaigns.pending]: state => {
      state.loadingAllCampaigns = true
    },
    [fetchAllCampaigns.fulfilled]: (state, action) => {
      state.loadingAllCampaigns = false
      state.allCampaigns = action.payload
    },
    [fetchAllCampaigns.rejected]: (state, action) => {
      state.loadingAllCampaigns = false
    }
  }
})


export const { setCampaign, setCampaignFormValid } = campaignsSlice.actions

export default campaignsSlice.reducer
