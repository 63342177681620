import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const unsubscribe = createAsyncThunk(
  'sentEmail/unsubscribe',
  async (params) => {
    const response = await api.sentEmails.unsubscribe(params)
    return response.data
  }
)

export const fetchSentEmail = createAsyncThunk(
  'sentEmail/fetchSentEmail',
  async ({ sentEmailId }) => {
    const response = await api.sentEmails.get(sentEmailId)
    return response.data
  }
)

const initialState = {
  loading: true,
  unsubscribeResponse: null,
  sentEmail: null,
}

export const sentEmailSlice = createSlice({
  name: 'sentEmail',
  initialState,
  reducers: {
    setUnsubscribeResponse: (state, action) => {
      state.unsubscribeResponse = action.payload
    },
    setSentEmail: (state, action) => {
      state.sentEmail = action.payload
    },
  },
  extraReducers: {
    [unsubscribe.pending]: (state) => {
      state.unsubscribeResponse = null
    },
    [unsubscribe.fulfilled]: (state, action) => {
      state.unsubscribeResponse = action.payload
    },
    [unsubscribe.rejected]: (state, action) => {
      state.unsubscribeResponse = null
    },
    [fetchSentEmail.pending]: (state) => {
      state.loading = true
    },
    [fetchSentEmail.fulfilled]: (state, action) => {
      state.sentEmail = action.payload
      state.loading = false
    },
    [fetchSentEmail.rejected]: (state, action) => {
      state.sentEmail = null
      state.loading = false
    },
  },
})

export const { setUnsubscribeResponse } = sentEmailSlice.actions

export default sentEmailSlice.reducer
