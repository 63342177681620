import 'focus-visible'
import '@/styles/tailwind.css'
import '@/styles/notification.css'
import 'react-tooltip/dist/react-tooltip.css'
import '@/styles/phone-input.css'
import { store } from '@/store/store'
import { Provider } from 'react-redux'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'
// import TrackerProvider from '@/contexts/tracker-context'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import CustomErrorPage from '@/components/CustomError'

const options = {
  api_host: 'https://app.posthog.com',
}

// Check that PostHog is client-side (used to handle Next.js SSR)
const POSTHOG_KEY =
  process.env.NODE_ENV === 'development'
    ? ''
    : process.env.NEXT_PUBLIC_POSTHOG_KEY
const POSTHOG_API = 'https://app.posthog.com'

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_API,
    // Enable debug mode in development
    loaded: (posthog) => {
      console.log(process.env.NODE_ENV)
      if (process.env.NODE_ENV === 'development') posthog.debug()
    },
  })
}

export default function App({ Component, pageProps: { ...pageProps } }) {
  const Layout = Component.Layout || EmptyLayout
  const Header = Component.Header

  return (
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <Layout Header={Header}>
          {/* <GoogleAnalytics trackPageViews gaMeasurementId="G-N5Y3J7VDNW" /> */}

          {/* <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script> */}

          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5M4B4HC');`,
            }}
          />

          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-5M4B4HC"
              height="0"
              width="0"
            ></iframe>
          </noscript>
          <Component {...pageProps} />
          <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
          >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
              <Toaster
                position="top-center"
                toastOptions={{
                  duration: 7000,
                }}
              />
            </div>
          </div>
        </Layout>
      </Provider>
    </PostHogProvider>
  )
}

const EmptyLayout = ({ children }) => {
  return <>{children}</>
}
