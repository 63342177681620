import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async (params) => {
    const response = await api.contacts.index(params)
    return response.data
  }
)

export const fetchContact = createAsyncThunk(
  'contacts/fetchContact',
  async ({ listId, contactId }) => {
    const response = await api.contacts.show(listId, contactId)
    return response.data
  }
)

const initialState = {
  list: {
    data: [],
    page: 1,
    perPage: 10,
    totalPages: 0,
  },
  currentContact: null,
  fetchingCurrentContact: true,
  loading: true,
  error: null,
}

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchContacts.pending]: (state) => {
      state.loading = true
    },
    [fetchContacts.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchContacts.rejected]: (state, action) => {
      state.list = []
      state.loading = false
    },
    [fetchContact.pending]: (state) => {
      state.fetchingCurrentContact = true
    },
    [fetchContact.fulfilled]: (state, action) => {
      state.currentContact = action.payload
      state.fetchingCurrentContact = false
    },
    [fetchContact.rejected]: (state, action) => {
      state.currentContact = {}
      state.fetchingCurrentContact = false
    },
  },
})

export default contactsSlice.reducer
