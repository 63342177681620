import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async() => {
    const response = await api.auth.validate()
    return response.data.data
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (params) => {
    await api.profile.update(params)
    const response = await api.auth.validate()
    return response.data
  }
)

const initialState = {
  currentUser: null,
  loading: true,
  error: null
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    }
  },
  extraReducers: {
    [fetchUser.pending]: state => {
      state.loading = true
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload
      state.loading = false
    },
    [fetchUser.rejected]: (state, action) => {
      state.currentUser = action.error
      state.loading = false
    },
    [updateProfile.pending]: state => {
      state.loading = true
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.currentUser = action.payload.data
      state.loading = false
    },
    [updateProfile.rejected]: (state, action) => {
      state.currentUser = action.error
      state.loading = false
    }
  }
})

export const { setCurrentUser } = sessionSlice.actions

export default sessionSlice.reducer
