import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'

export const fetchCampaign = createAsyncThunk(
  'whatsappCampaigns/fetchCampaign',
  async(id) => {
    const response = await api.whatsApp.campaigns.show(id)
    return response.data
  }
)

export const fetchCampaigns = createAsyncThunk(
  'whatsappCampaigns/fetchCampaigns',
  async(params) => {
    const response = await api.whatsApp.campaigns.index(params)
    return response.data
  }
)

export const fetchMailEvents = createAsyncThunk(
  'whatsappCampaigns/fetchMailEvents',
  async({ id, params }) => {
    const response = await api.whatsApp.campaigns.mailEvents(id, params)
    return response.data
  }
)

const initialState = {
  currentCampaign: { name: 'Untitled' },
  fetchingCurrentCampaign: true,
  fetchingMailEvents: true,
  campaignMailEvents: {
    data: [],
    page: 1,
    perPage: 20,
    totalPages: 0,
    total: 0
  },
  campaignsData: {
    data: [],
    page: 1,
    perPage: 10,
    total: 0,
    totalPages: 0
  },
  fetchingCampaigns: true
}

const whatsappCampaignSlice = createSlice({
  name: 'whatsappCampaigns',
  initialState,
  reducers: {
    updateCurrentCampaign: (state, action) => {
      state.currentCampaign = action.payload
    }
  },
  extraReducers: {
    [fetchCampaign.pending]: (state, action) => {
      state.fetchingCurrentCampaign = true
    },
    [fetchCampaign.fulfilled]: (state, action) => {
      state.currentCampaign = action.payload
      state.fetchingCurrentCampaign = false
    },
    [fetchCampaign.rejected]: (state, action) => {
      state.fetchingCurrentCampaign = false
    },
    [fetchCampaigns.pending]: (state, action) => {
      state.fetchingCampaigns = true
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.campaignsData = action.payload
      state.fetchingCampaigns = false
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.fetchingCampaigns = false
    },
    [fetchMailEvents.pending]: (state, action) => {
      state.fetchingMailEvents = true
    },
    [fetchMailEvents.fulfilled]: (state, action) => {
      state.campaignMailEvents = action.payload
      state.fetchingMailEvents = false
    },
    [fetchMailEvents.rejected]: (state, action) => {
      state.campaignMailEvents = {
        data: [],
        page: 1,
        perPage: 20,
        totalPages: 0,
        total: 0
      }
      state.loading = false
    }
  }
})

export const { updateCurrentCampaign } = whatsappCampaignSlice.actions

export default whatsappCampaignSlice.reducer
