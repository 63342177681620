import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'

export const fetchAutomation = createAsyncThunk(
  'automations/fetchAutomation',
  async (id) => {
    const response = await api.automations.show(id)
    return response.data
  }
)

// const initialState = {
//   automation: {
//     name: "Automationn 1",
//     automationTrigger: {
//       triggerType: 'email',
//       triggerValue: '',
//       ownerId: 1,
//       ownerType: 'List',
//       automationId: 1,
//     },
//     automationSteps: [
//       {
//         id: 1,
//         stepType: 'email',
//       },
//       {
//         id: 2,
//         parentStepId: 1,
//         stepType: 'condition',
//       },
//       {
//         id: 3,
//         parentStepId: 2,
//         stepType: 'delay',
//         parent_step_condition_met: true,
//       },
//       {
//         id: 4,
//         parentStepId: 2,
//         stepType: 'action',
//         parent_step_condition_met: false,
//       },
//       // {
//       //   id: 5,
//       //   parentStepId: 3,
//       //   stepType: 'condition',
//       // },
//       // {
//       //   id: 6,
//       //   parentStepId: 5,
//       //   stepType: 'condition',
//       //   parent_step_condition_met: false,
//       // },
//       // {
//       //   id: 7,
//       //   parentStepId: 5,
//       //   stepType: 'condition',
//       //   parent_step_condition_met: true,
//       // },

//     ]

//   },
//   loading: false,
//   error: null,
// };

const initialState = {
  automation: {
    name: 'Automationn 1',
    automationTrigger: {
      id: null,
      triggerType: null,
      triggerValue: null,
      ownerId: null,
      ownerType: null,
      automationId: null,
    },
    automationSteps: [],
  },
  loading: false,
  error: null,
  currentAutomationStep: null,
  currentAutomationStepForm: null,
}

export const automationsFormSlice = createSlice({
  name: 'automationForm',
  initialState,
  reducers: {
    getAutomationsStart(state) {
      state.loading = true
      state.error = null
    },
    updateForm(state, action) {
      state.automation = action.payload
    },
    updateTrigger(state, action) {
      state.automation.automationTrigger = action.payload
    },
    setCurrentAutomationStep(state, action) {
      state.currentAutomationStep = action.payload
    },
    setCurrentAutomationStepForm(state, action) {
      state.currentAutomationStepForm = action.payload
    },
    addStep(state, action) {
      state.automation.automationSteps.push(action.payload)
    },
    deleteStep(state, action) {
      const { stepId } = action.payload
      const stepIndex = state.automation.automationSteps.findIndex(
        (step) => step.id === stepId
      )
      state.automation.automationSteps.splice(stepIndex, 1)
    },
    updateStep(state, action) {
      const { stepId, stepType } = action.payload
      const stepIndex = state.automation.automationSteps.findIndex(
        (step) => step.id === stepId
      )
      state.automation.automationSteps[stepIndex].stepType = stepType
    },
  },
  extraReducers: {
    [fetchAutomation.pending]: (state, action) => {
      state.loading = true
    },
    [fetchAutomation.fulfilled]: (state, action) => {
      state.loading = false
      state.automation = action.payload
    },
    [fetchAutomation.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
      state.automation = null
    },
  },
})

export const {
  getAutomationsStart,
  updateForm,
  addStep,
  deleteStep,
  updateStep,
  updateTrigger,
  setCurrentAutomationStep,
  setCurrentAutomationStepForm,
  setStepFormId,
} = automationsFormSlice.actions
export default automationsFormSlice.reducer
