import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchLists = createAsyncThunk(
  'lists/fetchLists',
  async(params) => {
    const response = await api.lists.index(params)
    return response.data
  }
)

export const fetchList = createAsyncThunk(
  'lists/fetchList',
  async(id) => {
    const response = await api.lists.show(id)
    return response.data
  }
)

const initialState = {
  lists: {
    data: [],
    page: 1,
    perPage: 10,
    totalPages: 0
  },
  currentList: null,
  fetchingCurrentList: false,
  loading: true,
  error: null
}

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.currentList = action.payload
    }
  },
  extraReducers: {
    [fetchLists.pending]: state => {
      state.loading = true
    },
    [fetchLists.fulfilled]: (state, action) => {
      state.lists = action.payload
      state.loading = false
    },
    [fetchLists.rejected]: (state, action) => {
      state.lists = []
      state.loading = false
    },
    [fetchList.pending]: state => {
      state.fetchingCurrentList = true
    },
    [fetchList.fulfilled]: (state, action) => {
      state.currentList = action.payload
      state.fetchingCurrentList = false
    },
    [fetchList.rejected]: (state, action) => {
      state.lists = {}
      state.fetchingCurrentList = false
    }
  }
})


export const {setList} = listsSlice.actions

export default listsSlice.reducer
