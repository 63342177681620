import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchGetStarted = createAsyncThunk(
  'dashboard/fetchGetStarted',
  async () => {
    const response = await api.dashboard.getStarted()
    return response.data
  }
)

export const fetchMonthlyReport = createAsyncThunk(
  'dashboard/fetchMonthlyReport',
  async () => {
    const response = await api.dashboard.monthlyReport()
    return response.data
  }
)

const initialState = {
  getStarted: null,
  monthlyReport: [],
  loading: true
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setGetStarted: (state, action) => {
      state.getStarted = action.payload
    },
    setMonthlyReport: (state, action) => {
      state.monthlyReport = action.payload
    },
  },
  extraReducers: {
    [fetchGetStarted.pending]: (state) => {
      state.loading = true
    },
    [fetchGetStarted.fulfilled]: (state, action) => {
      state.getStarted = action.payload
      state.loading = false
    },
    [fetchGetStarted.rejected]: (state, action) => {
      state.getStarted = action.error
      state.loading = false
    },
    [fetchMonthlyReport.pending]: (state) => {
      state.loading = true
    },
    [fetchMonthlyReport.fulfilled]: (state, action) => {
      state.monthlyReport = action.payload
      state.loading = false
    },
    [fetchMonthlyReport.rejected]: (state, action) => {
      state.monthlyReport = action.error
      state.loading = false
    },
  },
})

export const { setGetStarted } = dashboardSlice.actions

export default dashboardSlice.reducer
