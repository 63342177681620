import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'
export const fetchTemplates = createAsyncThunk(
  'campaigns/fetchTemplates',
  async(params) => {
    let response;
    if (params['is_public']) {
      response = await api.templates.public(params)
    } else {
      response = await api.templates.index(params)
    }
    return response.data
  }
)

export const fetchTemplate = createAsyncThunk(
  'campaigns/fetchTemplate',
  async(id) => {
    const response = await api.templates.show(id)
    return response.data
  }
)

// export const fetchDefaultTemplate = createAsyncThunk(
//   'campaigns/fetchDefaultTemplate',
//   async(id) => {
//     const response = await api.templates.show(id)
//     return response.data
//   }
// )

const initialState = {
  templatesData: {
    data: [],
    page: 1,
    perPage: 20
  },
  currentTemplate: null,
  fetchingCurrentTemplate: false,
  loading: true,
  error: null
}

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setCurrentTemplate: (state, action) => {
      state.currentTemplate = action.payload
    }
  },
  extraReducers: {
    [fetchTemplates.pending]: state => {
      state.loading = true
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      if (action.payload.page == 1) {
        state.templatesData = action.payload
      } else {
        state.templatesData.data = [...state.templatesData.data, ...action.payload.data]
        state.templatesData.page = action.payload.page
        state.templatesData.perPage = action.payload.perPage
        state.templatesData.total = action.payload.total
      }

      state.loading = false
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.templatesData = {data: [], page: 1, perPage: 10}
      state.loading = false
    },
    [fetchTemplate.pending]: state => {
      state.fetchingCurrentTemplate = true
    },
    [fetchTemplate.fulfilled]: (state, action) => {
      state.currentTemplate = action.payload
      state.fetchingCurrentTemplate = false
    },
    [fetchTemplate.rejected]: (state, action) => {
      state.currentTemplate = null
      state.fetchingCurrentTemplate = false
    }
  }
})


export const { setTemplate, setCurrentTemplate } = templatesSlice.actions

export default templatesSlice.reducer
