import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@/api'

export const fetchConversations = createAsyncThunk(
  'waInbox/fetchConversations',
  async (params) => {
    const response = await api.whatsApp.conversations.index(params)
    return response.data
  }
)

export const fetchMessages = createAsyncThunk(
  'waInbox/fetchMessages',
  async (params) => {
    const response = await api.whatsApp.conversations.messages(params.id, params)
    return response.data
  }
)

export const pollMessages = createAsyncThunk(
  'waInbox/pollMessages',
  async (params) => {
    const response = await api.whatsApp.conversations.messages(params.id, params)
    return response.data
  }
)

export const fetchConversation = createAsyncThunk(
  'waInbox/fetchConversation',
  async (id) => {
    const response = await api.whatsApp.conversations.show(id)
    return response.data
  }
)

const initialState = {
  agents: [],
  conversations: {
    data: [],
    page: 1,
    perPage: 50,
    totalPages: 0,
    total: 0
  },
  messages: {
    data: [],
    page: 1,
    perPage: 10,
    totalPages: 0,
    total: 0
  },
  fetchingMessages: false,
  currentConversation: null,
  fetchingConversation: false,
}

const waInboxSlice = createSlice({
  name: 'waInbox',
  initialState,
  reducers: {
    resetMessages: (state) => {
      state.messages = {
        data: [],
        page: 1,
        perPage: 10,
        totalPages: 0,
        total: 0
      }
    },
    setConversations: (state, action) => {
      state.conversations = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setCurrentConversation: (state, action) => {
      state.currentConversation = action.payload
    }
  },
  extraReducers: {
    [fetchConversations.pending]: (state) => {
      state.fetchingConversation = true
    },
    [fetchConversations.fulfilled]: (state, action) => {
      state.conversations = action.payload
      state.fetchingConversation = false
    },
    [fetchConversations.rejected]: (state, action) => {
      state.fetchingConversation = false
    },
    [fetchMessages.pending]: (state) => {
      state.fetchingMessages = true
    },
    [fetchMessages.fulfilled]: (state, action) => {
      // if (action.payload.page > 1) {
      //   state.messages.data = [...state.messages.data, ...action.payload.data]
      //   state.messages.page = action.payload.page
      //   state.messages.perPage = action.payload.perPage
      //   state.messages.totalPages = action.payload.totalPages
      // } else {
      //   state.messages = action.payload
      // }
      // // state.messages = action.payload
      // state.fetchMessages = false
      const existingMessages = state.messages.data || [];

      // Filter out any messages that already exist
      const newMessages = action.payload.data.filter(
        (msg) => !existingMessages.some((existingMsg) => existingMsg.id === msg.id)
      );

      // Append new messages to the existing array
      state.messages.data = [...newMessages, ...existingMessages];

      // Update pagination details in the state
      state.messages.page = action.payload.page;
      state.messages.perPage = action.payload.perPage;
      state.messages.totalPages = action.payload.totalPages;

      state.fetchingMessages = false;
    },
    [pollMessages.pending]: (state) => {
      // state.fetchingMessages = true
    },
    [pollMessages.fulfilled]: (state, action) => {
      const existingMessages = state.messages.data || [];
      // Check for new messages that are not in the existing array
      const newMessages = action.payload.data.filter(
        (msg) => !existingMessages.some((existingMsg) => existingMsg.id === msg.id)
      );
      // Prepend new messages
      state.messages.data = [...newMessages, ...existingMessages];
    },
    [fetchMessages.rejected]: (state, action) => {
      state.fetchingMessages = false
    },
    [fetchConversation.pending]: (state) => {
      state.fetchConversation = true
    },
    [fetchConversation.fulfilled]: (state, action) => {
      state.currentConversation = action.payload
      state.fetchConversation = false
    },
    [fetchConversation.rejected]: (state, action) => {
      state.fetchConversation = false
    }
  }
})

export const { setConversations, setMessages, setCurrentConversation, resetMessages } = waInboxSlice.actions

export default waInboxSlice.reducer
