import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import {
  XMarkIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'
import { toast } from 'react-hot-toast'

export const Warning = function ({ title, message, t }) {
  return (
    <>
      <Transition
        show={t.visible}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border border-yellow-200 bg-yellow-50">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium leading-none text-yellow-700">
                  {title}
                </p>
                <p className="mt-2 text-sm text-gray-600">{message}</p>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-yellow-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    toast.dismiss(t.id)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export const Success = function ({ title, message, t }) {
  return (
    <>
      <Transition
        show={t.visible}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border border-green-200 bg-green-50">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium leading-none text-green-700">
                  {title}
                </p>
                <p className="mt-2 text-sm text-gray-600">{message}</p>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-green-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    toast.dismiss(t.id)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export const Danger = function ({ title, message, t, cta, ctaLink }) {
  return (
    <>
      <Transition
        show={t.visible}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border border-red-200 bg-red-50">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium leading-none text-red-700">
                  {title}
                </p>
                <p className="mt-2 text-sm text-gray-600">{message}</p>
                {ctaLink ? (
                  <div className="mt-2 flex space-x-7">
                    <a
                      href={ctaLink}
                      className="rounded-md bg-red-50 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                    >
                      {cta ? cta : 'Fix it'}{' ->'}
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-red-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    toast.dismiss(t.id)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}
