import { toast } from 'react-hot-toast'
import { Success, Warning, Danger } from '@/components/core/Notifications'

export const showToast = function (type, title, message, cta = null, ctaLink = null) {
  if (type == 'success') {
    toast.custom((t) => <Success title={title} message={message} t={t} />)
  }

  if (type == 'danger') {
    toast.custom((t) => <Danger title={title} message={message} t={t} cta={cta} ctaLink={ctaLink} />)
  }

  if (type == 'warning') {
    toast.custom((t) => <Warning title={title} message={message} t={t} />)
  }
}
